//
//	Site Breakpoint mixin
//
//***********************************************************************/
@mixin bp($point) {
	$bp-mobile: "(max-width:" + (320 / 16) + "em)";
	$bp-small: "(min-width:" + (500 / 16) + "em)";
	$bp-medium: "(min-width:" + (760 / 16) + "em)";
	$bp-large: "(min-width:" + (900 / 16) + "em)";
	$bp-larger: "(min-width:" + (1100 / 16) + "em)";
	$bp-largest: "(min-width:" + (1200 / 16) + "em)";

	@if $point == mobile {
		@media only screen and #{$bp-mobile} { @content; }
	}
	@else if $point == small {
		@media only screen and #{$bp-small} { @content; }
	}
	@else if $point == medium {
		@media only screen and #{$bp-medium} { @content; }
	}
	@else if $point == large {
		@media only screen and #{$bp-large} { @content; }
	}
	@else if $point == larger {
		@media only screen and #{$bp-larger} { @content; }
	}
	@else if $point == largest {
		@media only screen and #{$bp-largest}  { @content; }
	}
}