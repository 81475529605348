//
// Site variables
//
//***********************************************************************/

//
// Fonts
//
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$poppins: 'Poppins', sans-serif;

// Fonts: Assignments
$font-body: $font-family-sans-serif;
$font-heading: $font-family-sans-serif;
$font-feature: $poppins;

// Fonts: Weights
$font-weight--bold: 700;
$font-weight--extra-bold: 800;

//
// Colours
//
$white: rgb(255,255,255);
$body-black: rgb(33, 37, 41);
$off-black: rgb(65, 65, 65);
$off-white: rgb(244, 244, 244);
$grey: rgb(151, 151, 151);

$neon-teal: rgb(27, 199, 159);

$jwl-pink: rgb(238, 21, 195);

// Colours: Assignments
$colour-background: $off-white;
$colour-primary: $body-black;
$colour-secondary: $off-black;
$colour-body: $body-black;
$colour-body--secondary: $off-black;
$colour-border: $grey;
$colour-link-hover: $neon-teal;

// Colours: Buttons
$colour-button-primary: $neon-teal;
$colour-button-txt: $neon-teal;