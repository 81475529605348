//
// Editorial styles (.editorial)
//
//***********************************************************************/
.editorial {

	// For line-length, restrict the max-width of editorial blocks
	max-width: 690px;

	// Headings
	h1, h2, h3, h4, h5 { margin-bottom: 12px; }

	// Links
	a { color: $colour-primary; }
		a:hover { color: $colour-link-hover; }

	// Body text
	p { @include font-size(17); margin-bottom: 2em; line-height: 2; text-align: justify; }
		p:last-child { margin-bottom: 0; }

	// Stand First
	.editorial__stand-first { @include font-size(24); font-weight: 300; }

	// Lists
	ul, ol { @include font-size(16); margin: 0 0 12px 20px; }

	li { list-style-position: outside; padding: 9px 0; }
		ul li { list-style: disc; }
		ol li { list-style: decimal; }

		ul ul, ol ol { font-size: 1em; }
		ul ul li { list-style: circle; }

	ul.al-anchor-list { margin-bottom: 8px; }
		ul.al-anchor-list li { list-style: square; padding: 1px 0; }

	// Images
	img { display: inline; margin-bottom: 15px; }

		.editorial__img-left { float: left; margin: 0 15px 15px 0px; }
		.editorial__img-right { float: right; margin: 0 0 15px 15px; }

	.editorial__img { display: block; margin: 15px 0; }

	// Tables
	table {
		width: 100%;
		@include font-size(13);
		text-align: left;
		border: 1px solid $colour-primary;
		border-width: 1px 0 2px;
		margin: 0 0 12px;
	}

		// Headings
		table tr th {
			font-weight: bold;
			background: $white;
			border-bottom: 2px solid $colour-secondary;
			padding: 8px;
		}

		// Rows
		table td {
			background: lighten(desaturate($colour-secondary, 15%), 55%);
			padding: 7px 8px;
			vertical-align: middle;
			border-bottom: 1px solid $white;
		}
		table tr:nth-child(odd) td { background:lighten(desaturate($colour-primary, 25%), 65%); }

	// iFrames
	iframe.noscroll {
		overflow-y: hidden;
	}
}