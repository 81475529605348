//
// Button (.button)
//
//***********************************************************************
.button {
	background-color: transparent;
	border: 2px solid $colour-button-primary;
	@include font-size(20);
	@include rem(padding, 12px 25px);
	border-radius: 10px;
	margin: 0;
	font-weight: normal;
	font-family: $font-heading;
	color: $colour-button-txt;
	line-height: 1;
	display: block;
	cursor: pointer;
	overflow: visible;
	text-decoration: none;
	transition: background-color .2s ease-in-out;
	text-align: center;
	position: relative;
}
	.button:hover,
	.button:focus,
	a.button:hover,
	a.button:focus { background-color: lighten($colour-button-primary, 10%); color: #fff; }

	// link specific
	a.button { text-decoration: none; display: inline-block; color: $colour-button-txt; }
