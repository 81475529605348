//
// Site panels (.panel)
//
//***********************************************************************
.panel {
	width: 100%;
}

	.panel__image {

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			max-height: 650px;

			@include bp(medium) {
				max-height: none;
			}
		}
	}

	.panel__content {
		background: $white;
		@include rem(padding, 30px);
	}

	// Responsive Layout

	@include bp(medium) {
		.panel {
			display: flex;
		}
			.panel__image,
			.panel__content { width: calc(100%/2); }

			.panel__image { }
			.panel__content {
				@include rem(padding, 50px);
				display: flex;
				align-items: center;
			}
	}

		.panel__heading {
			font-family: $font-feature;
			text-transform: uppercase;
			@include font-size(54);
			font-weight: $font-weight--extra-bold;
			color: $colour-body--secondary;
			text-align: center;
			line-height: 1;
			position: relative;
			@include rem(padding, 10px 0 30px);
			@include rem(margin-bottom, 30px);

			&:after {
				content: "";
				position: absolute;
				border-bottom: 1px solid $colour-border;
				z-index: 10000;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 70%;
			}

			@include bp(medium) {
				@include font-size(64);
				@include rem(padding, 0 0 40px);
				@include rem(margin-bottom, 40px);
			}
		}

		.panel__body { }

			.panel__buttons {

				margin: 2em 0;

				.button { width: 100%; }
					.button:first-child { margin-bottom: 20px; }

				@include bp(medium) {
					display: flex;
					justify-content: center;
					margin: 3em 0;

					.button,
					.button:first-child { width: auto; margin: 0 15px; }
				}


			}

		.panel__agent-area p {
			text-align: right;
			a {
				color: $jwl-pink;
			}
		}

		.panel__footer {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-top: 70px;
		}

			.panel__social-links,
			.panel__cv-links {
				display: flex;
				align-items: center;
				gap: 20px;
				a { border: none; display: block; }
			}
			.panel__social-links {
				margin-right: 15px;
				a:hover path { fill: $colour-link-hover; }
			}
			.panel__cv-links {
				img { max-height: 64px; }
					a:hover img { opacity: 0.8; }
			}

